import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBNEJwGVcDEQvC1AdFIM_2RhtSd4Qo8bIM",
  authDomain: "life-sweeven-calculator.firebaseapp.com",
  projectId: "life-sweeven-calculator",
  storageBucket: "life-sweeven-calculator.appspot.com",
  messagingSenderId: "398262458321",
  appId: "1:398262458321:web:2b3f5504488f7c986d72f0",
  measurementId: "G-16NT2XP019",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics, logEvent };
