export function getColorByIndex(index) {
  switch (index) {
    case 0:
      return "#5DCA83";
    case 1:
      return "#ED6560";
    case 2:
      return "#6E44B7";
    case 3:
      return "#5EB3E1";
    default:
      return "#5DCA83";
  }
}
