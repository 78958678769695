import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import BasePage from "../pages/BasePage";
import { Grid, Typography, Box, Stack, List } from "@mui/material";
import { getColorByIndex } from "../extensions/Tools";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export default function SurveyPage(props) {
  const theme = useTheme();
  const location = useLocation();
  //const sick = location.state ? location.state.data : null;
  const navigate = useNavigate();

  const questions = [
    {
      question:
        "¿Sientes que te falta energía durante el día? ¿Te cuesta trabajo completar tus actividades?    ",
      product: "21000103",
    },
    {
      question:
        "¿Padeces de ansiedad y/o depresión y te cuesta trabajo el manejo de tus emociones?	",
      product: "21000102",
    },
    {
      question: "¿Te sientes inquieto, ansioso o nervioso?",
      product: "21000102",
    },
    {
      question:
        "¿Te sientes desmotivado o apático, te cuesta trabajo levantarte para iniciar tu día?",
      product: "21000102",
    },
    {
      question:
        "¿Tienes mucho trabajo, necesitas terminar tus pendientes pero no estás concentrado? ",
      product: "21000101",
    },
    {
      question: "¿Padeces dolores musculares frecuentes y migrañas?",
      product: "21000104",
    },
    {
      question:
        "¿Tienes dolores musculares por ejercicio, por torceduras o raspones?",
      product: "21000126",
    },
    {
      question: "¿Tienes sobrepeso y no sabes qué hacer?",
      product: "21000115",
    },
    // {
    //   question: "¿Tienes arrugas en el rostro y ojos y tu piel se ve marchita?",
    //   product: "DERMA/EYE SERUM/POWER GUMMIES",
    // },
    {
      question: "¿Te cuesta trabajo dormir y no tienes un descanso reparador?",
      product: "21000106",
    },
  ];

  const handleOptionSelected = (option) => {
    navigate("/product-detail", { state: { data: option.product } });
  };

  const itemBackgroundColor = () => {
    return theme.palette.mode !== "dark"
      ? "linear-gradient(90deg, #DFE7F4 0%, #F5F9FD 100%)"
      : "linear-gradient(90deg, #252629 0%, #000000 100%)";
  };

  const renderQuestions = () => {
    return questions.map((option, index) => {
      return (
        <Box
          key={option.question}
          sx={{
            borderRadius: 10,

            background: itemBackgroundColor(),
            p: 2,
            borderWidth: 5,
            borderColor: getColorByIndex(index % 4),
            borderStyle: "solid",
          }}
          onClick={() => {
            handleOptionSelected(option);
          }}
        >
          <Typography variant="h5">{option.question}</Typography>
        </Box>
      );
    });
  };

  const renderView = () => {
    return (
      <Stack
        sx={{ maxWidth: "md", margin: "auto", overflow: "hidden" }}
        spacing={3}
      >
        {renderQuestions()}
      </Stack>
    );
  };

  return (
    <BasePage>
      <Stack
        sx={{ maxWidth: "md", margin: "auto", overflow: "hidden" }}
        spacing={3}
      >
        {renderQuestions()}
      </Stack>
    </BasePage>
  );
}
