import React from "react";
import BasePage from "../pages/BasePage";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography, Box, Grid, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import WarningIcon from "../images/warning_icon.svg";
import DescubreIcon from "../images/descubre_icon.png";
import HandsIcon from "../images/icons/hand.svg";
import { Star } from "@mui/icons-material";

export default function HomePage() {
  const navigate = useNavigate();
  const theme = useTheme();
  // const itemSelected = (type, link) => {
  //   if (type === 1) {
  //     window.open(link, "_parent");
  //   }
  // };

  const goToCalculator = () => {
    navigate("/sicks");
  };

  const goToSurvey = () => {
    navigate("/survey");
  };

  return (
    <BasePage disableHeader={true} disableTopWarning={true}>
      <Stack spacing={3} sx={{ alignItems: "center" }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
          {"¿Cómo lo uso?"}
        </Typography>
        <Stack direction={"row"} alignItems={"center"}>
          <Box
            m={3}
            sx={{ width: "7vw", height: "auto" }}
            component="img"
            src={HandsIcon}
          />

          <Box>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "3vw",
                fontFamily: "Montserrat",
                fontWeight: "900",
                mb: { xs: -1, md: -2, lg: -3 },
              }}
            >
              {"DESCUBRE"}
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "2vw",
                fontFamily: "Montserrat",
                fontWeight: "900",
              }}
            >
              {"TU BIENESTAR"}
            </Typography>
          </Box>
        </Stack>
        <Typography
          sx={{
            WebkitTextStroke: `1px ${
              theme.palette.mode === "dark" ? "#FFF" : "#000"
            }`,
            color: "transparent",
            fontFamily: '"Montserrat-Regular", Helvetica',
            fontSize: "7vw",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          BIENVENIDOS
        </Typography>
        <Typography variant="body" sx={{ fontWeight: "bold" }}>
          {
            "NUESTROS PRODUCTOS ESTÁN RECOMENDADOS PARA MEJORAR Y COADYUVAR LA CALIDAD DE VIDA DE LAS PERSONAS"
          }
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{
                backgroundColor: "#FEDB13",
                color: "#000",
                borderRadius: 3,
                p: 2,
                mb: 2,
              }}
            >
              <Grid item xs={2}>
                <Box
                  component="img"
                  src={WarningIcon}
                  sx={{ height: "5vh" }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {
                    "ESTE PRODUCTO NO ES UN MEDICAMENTO, POR LO TANTO NO CURA ENFERMEDADES."
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderStyle: "dashed",
                p: 2,
              }}
            >
              <Typography variant="body" sx={{}}>
                {
                  "NUESTROS PRODUCTOS NO CURAN ENFERMEDADES, NI SUSTITUYEN NINGUN TRATAMIENTO MÉDICO EL CONSUMO SUGERIDO CONTRIBUYE A TU BIENESTAR Y CALIDAD DE VIDA."
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="body" sx={{ fontWeight: "bold" }}>
          {
            "EL CONSUMO DE ESTE PRODUCTO ES RESPONSABILIDAD DE QUIÉN LO RECOMIENDA Y DE QUIEN LO USA."
          }
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          {"¿Cúal es el producto ideal para mi?"}
        </Typography>
        <Button
          size="large"
          variant="contained"
          sx={{
            background: "#078EBD",
            fontSize: "3vh",
            fontWeight: "bold",
            borderRadius: "2vh",
          }}
          onClick={goToSurvey}
        >
          {"¡CLICK AQUI PARA DESCUBRIR!"}
        </Button>
        <Stack spacing={2} direction="row">
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              background: "#058D49",
              fontSize: "3vh",
              fontWeight: "bold",
              borderRadius: "2vh",
            }}
            onClick={goToCalculator}
          >
            {"RECOMENDACIONES DE USO"}
          </Button>
        </Stack>
      </Stack>
      {/* <Grid container margin={2}>
        <Grid item xs={10}>
          <Stack
            spacing={3}
            sx={{
              color: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {"¿Cómo lo uso?"}
            </Typography>
            <Box
              sx={{
                width: "50%",
                height: "auto",
              }}
              component="img"
              src={DescubreIcon}
            />
            <Typography variant="h2" sx={{}}>
              {"BIENVENIDOS"}
            </Typography>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {
                "NUESTROS PRODUCTOS ESTÁN RECOMENDADOS PARA MEJORAR Y COADYUVAR LA CALIDAD DE VIDA DE LAS PERSONAS"
              }
            </Typography>
            <Grid container>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "#373737",
                    textAlign: "left",
                    borderRadius: 3,
                    p: 2,
                  }}
                >
                  <Typography variant="body" sx={{ fontWeight: "bold" }}>
                    {"INSTRUCCIONES:"}
                  </Typography>
                  <Typography variant="body" sx={{}}>
                    {
                      " BUSCA DENTRO DEL LISTADO QUE A CONTINUACIÓN TE PRESENTAREMOS, EL PADECIMIENTO, ENFERMEDAD O ALTERACION DE LA SALUD Y DALE CLIC."
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid
              container
              sx={{
                backgroundColor: "#FEDB13",
                color: "#000",
                borderRadius: 3,
                p: 2,
              }}
            >
              <Grid item xs={2}>
                <Box
                  component="img"
                  src={WarningIcon}
                  sx={{ height: "5vh" }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {
                    "ESTE PRODUCTO NO ES UN MEDICAMENTO, POR LO TANTO NO CURA ENFERMEDADES."
                  }
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                borderStyle: "dashed",
                p: 2,
              }}
            >
              <Typography variant="body" sx={{}}>
                {
                  "NUESTROS PRODUCTOS NO CURAN ENFERMEDADES, NI SUSTITUYEN NINGUN TRATAMIENTO MÉDICO EL CONSUMO SUGERIDO CONTRIBUYE A TU BIENESTAR Y CALIDAD DE VIDA."
                }
              </Typography>
            </Box>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {
                "EL CONSUMO DE ESTE PRODUCTO ES RESPONSABILIDAD DE QUIÉN LO RECOMIENDA Y DE QUIEN LO USA."
              }
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2} sx={{ m: "auto" }}>
          <KeyboardArrowRightIcon
            sx={{ color: "white", fontSize: "10vw" }}
            onClick={goToCalculator}
          />
        </Grid>
      </Grid> */}
    </BasePage>
  );
}
