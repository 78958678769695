import React, { useEffect, useState } from "react";
import BasePage from "../pages/BasePage";
import { Grid, Typography, Box, Stack } from "@mui/material";
import HarmonyImage from "../images/Harmony.png";
import EnergizeImage from "../images/Energize.png";
import FocusImage from "../images/Focus.png";
import HealthImage from "../images/Health.png";
import GoteroImage from "../images/gotero.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export default function OilDetailPage(props) {
  const location = useLocation();
  const sick = location.state ? location.state.data : null;
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.state === null) {
  //     navigate("/calculator/list");
  //   } else {
  //     setSick(location.state.sick);
  //   }
  // }, [navigate, location.state]);

  const chooseColor = (oil) => {
    switch (oil) {
      case "harmony":
        return "#5DCA83";
      case "energize":
        return "#ED6560";
      case "focus":
        return "#6E44B7";
      case "health":
        return "#5EB3E1";
      default:
        return "#5DCA83";
    }
  };

  const chooseImage = (oil) => {
    switch (oil) {
      case "harmony":
        return HarmonyImage;
      case "energize":
        return EnergizeImage;
      case "focus":
        return FocusImage;
      case "health":
        return HealthImage;
      default:
        return HarmonyImage;
    }
  };

  const semiCircle = {
    position: "absolute",

    borderRadius: "0 50% 50% 0",
    transform: "translate(-50%, 0)",
    backgroundColor: "green",
    height: "40vh",
  };

  const renderDosis = (dosis) => {
    let currentColor = chooseColor(sick.product.toLowerCase());
    return (
      <Stack align="center">
        {dosis.map((option) => {
          return (
            <Box align="center" key={option.title}>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {option.title}
              </Typography>
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  backgroundColor: currentColor,
                  borderRadius: 40,
                }}
              >
                <Stack align="center" spacing={-0.9}>
                  <Box
                    component="img"
                    src={GoteroImage}
                    sx={{ height: 50 }}
                  ></Box>
                  <Box>
                    <Typography>{option.drops}</Typography>
                  </Box>
                  <Typography variant="caption">{"gotas"}</Typography>
                </Stack>
              </Box>
            </Box>
          );
        })}
      </Stack>
    );
  };

  const renderView = () => {
    let currentColor = chooseColor(sick.product.toLowerCase());
    let currentImage = chooseImage(sick.product.toLowerCase());
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        {/* TITLE */}
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h4"
          >
            {sick.sick}
          </Typography>
        </Grid>
        {/* LINE */}
        <Grid
          item
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              height: 10,
              width: "80%",
              backgroundColor: currentColor,
              borderRadius: 10,
            }}
          ></Box>
        </Grid>
        {/* TEXT */}
        <Grid item xs={10}>
          <Box sx={{ p: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {"Ventajas / Virtudes"}
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                textAlign: "left",
              }}
            >
              {sick.Ventajas_virtudes}
            </Typography>
          </Box>
        </Grid>
        {/* BANNER */}
        <Grid
          item
          style={{ display: "flex", justifyContent: "flex-end" }}
          xs={12}
        >
          <Box
            sx={{
              backgroundColor: currentColor,
              borderRadius: 10,
              boxShadow: "5px 5px 5px black",
              p: 1,
            }}
          >
            <Typography variant="body" sx={{ fontWeight: "medium" }}>
              {"DOSIS (3 VECES AL DÍA)"}
            </Typography>
          </Box>
        </Grid>
        {/* IMAGE */}
        <Grid item xs={6} sx={{ position: "relative" }}>
          <Box></Box>
          <Box component="img" src={currentImage} sx={{ height: "40vh" }}></Box>
        </Grid>
        {/* DROPS */}
        <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
          {renderDosis([
            { title: "INICIO", drops: sick.Inicio },
            { title: "MANTENIMIENTO", drops: sick.MTTO },
            { title: "EXTREMO", drops: sick.Extrema },
          ])}
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage>
      <Box sx={{ maxWidth: "md", margin: "auto", overflow: "hidden" }}>
        <Box align="right">
          <CancelIcon
            sx={{ fontSize: 30 }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>
        {sick ? renderView() : <Typography>{"NOT SELECTED"}</Typography>}
      </Box>
    </BasePage>
  );
}
