import { ReactComponent as Energize1 } from "../images/icons/energize_icon_1.svg";
import { ReactComponent as Energize2 } from "../images/icons/energize_icon_2.svg";
import { ReactComponent as Energize3 } from "../images/icons/energize_icon_3.svg";
import { ReactComponent as Focus1 } from "../images/icons/focus_icon_1.svg";
import { ReactComponent as Focus2 } from "../images/icons/focus_icon_2.svg";
import { ReactComponent as Focus3 } from "../images/icons/focus_icon_3.svg";
import { ReactComponent as Health1 } from "../images/icons/health_icon_1.svg";
import { ReactComponent as Health2 } from "../images/icons/health_icon_2.svg";
import { ReactComponent as Health3 } from "../images/icons/health_icon_3.svg";
import { ReactComponent as Harmony1 } from "../images/icons/harmony_icon_1.svg";
import { ReactComponent as Harmony2 } from "../images/icons/harmony_icon_2.svg";
import { ReactComponent as Harmony3 } from "../images/icons/harmony_icon_3.svg";
import SvgIcon from "@mui/material/SvgIcon";

const EnergizeIcon1 = (props) => {
  return <SvgIcon {...props} component={Energize1} inheritViewBox />;
};

const EnergizeIcon2 = (props) => {
  return <SvgIcon {...props} component={Energize2} inheritViewBox />;
};

const EnergizeIcon3 = (props) => {
  return <SvgIcon {...props} component={Energize3} inheritViewBox />;
};

const FocusIcon1 = (props) => {
  return <SvgIcon {...props} component={Focus1} inheritViewBox />;
};

const FocusIcon2 = (props) => {
  return <SvgIcon {...props} component={Focus2} inheritViewBox />;
};

const FocusIcon3 = (props) => {
  return <SvgIcon {...props} component={Focus3} inheritViewBox />;
};

const HealthIcon1 = (props) => {
  return <SvgIcon {...props} component={Health1} inheritViewBox />;
};

const HealthIcon2 = (props) => {
  return <SvgIcon {...props} component={Health2} inheritViewBox />;
};

const HealthIcon3 = (props) => {
  return <SvgIcon {...props} component={Health3} inheritViewBox />;
};

const HarmonyIcon1 = (props) => {
  return <SvgIcon {...props} component={Harmony1} inheritViewBox />;
};

const HarmonyIcon2 = (props) => {
  return <SvgIcon {...props} component={Harmony2} inheritViewBox />;
};

const HarmonyIcon3 = (props) => {
  return <SvgIcon {...props} component={Harmony3} inheritViewBox />;
};

const resolveIcon = (icon) => {
  switch (icon) {
    case "energize_icon_1":
      return EnergizeIcon1;
    case "energize_icon_2":
      return EnergizeIcon2;
    case "energize_icon_3":
      return EnergizeIcon3;
    case "health_icon_1":
      return HealthIcon1;
    case "health_icon_2":
      return HealthIcon2;
    case "health_icon_3":
      return HealthIcon3;
    case "focus_icon_1":
      return FocusIcon1;
    case "focus_icon_2":
      return FocusIcon2;
    case "focus_icon_3":
      return FocusIcon3;
    case "harmony_icon_1":
      return HarmonyIcon1;
    case "harmony_icon_2":
      return HarmonyIcon2;
    case "harmony_icon_3":
      return HarmonyIcon3;
    default:
      return EnergizeIcon1;
  }
};

export { EnergizeIcon1, EnergizeIcon2, EnergizeIcon3, resolveIcon };
