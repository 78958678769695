import React from "react";
import { useTheme } from "@mui/material/styles";
import BasePage from "../pages/BasePage";
import {
  Box,
  Link,
  Stack,
  Alert,
  Typography,
  Tooltip,
  IconButton,
  Snackbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// import { useTranslation } from "react-i18next";
import logo from "../images/logo_dark.svg";
import logoLight from "../images/logo_light.svg";

export default function SuccessPage() {
  // const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText("dealerURL");
      setOpen(true);
    } catch (error) {
      //TODO: HANDLE ERROR
    }
  };

  return (
    <BasePage disableHeader={true} disableTopWarning={true}>
      <Box>
        <Box
          component="img"
          sx={{
            m: 3,
            width: { xs: "25vw", sm: "10vw" },
            height: "auto",
          }}
          src={theme.palette.mode === "dark" ? logo : logoLight}
        />
        <Stack spacing={3}>
          <Typography
            color="harmony.main"
            variant="h2"
            sx={{ fontWeight: "Bold" }}
          >
            {"¡Muchas gracias!"}
          </Typography>
          <Typography
            variant="h3"
            color="white"
            sx={{ mt: 6, fontWeight: "Bold" }}
          >
            {"TU CODIGO ES"}
          </Typography>
          <Typography
            variant="h2"
            sx={{ fontWeight: "Bold", textDecoration: "underline" }}
            onClick={handleClick}
          >
            {"BIENESTAR25"}
            <Tooltip title={""}>
              <IconButton onClick={handleClick}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: "Bold" }}>
            {"TOCA EL CODIGFO PARA COPIARLO"}
          </Typography>
          <div>
            <Typography
              variant="h3"
              sx={{ color: "harmony.main", fontWeight: 700 }}
            >
              Ir a Sweeven
            </Typography>
            <Link
              color={
                theme.palette.mode === "dark" ? "white.main" : "black.main"
              }
              href={"https://sweeven.life/discount/BIENESTAR25"}
              sx={{ fontWeight: "Bold", fontSize: "1.5rem" }}
            >
              sweeven.life
            </Link>
          </div>
        </Stack>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Enlace copiado
        </Alert>
      </Snackbar>
    </BasePage>
  );
}
