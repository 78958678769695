import React from "react";

import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const { onDrawerToggle } = props;
  const navigator = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMoreMenu, setAnchorMoreMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMoreMenu = Boolean(anchorMoreMenu);

  // const mainMenu = [
  //   {
  //     id: 1,
  //     name: "Productos",
  //     toRoute: "/",
  //   },
  //   {
  //     id: 2,
  //     name: "Comunidad",
  //     toRoute: "/clients/list",
  //   },
  //   {
  //     id: 3,
  //     name: "Distribuidores",
  //     toRoute: "/medical/list",
  //   },
  //   {
  //     id: 4,
  //     name: "Contacto",
  //     toRoute: "/appointments/list",
  //   },
  // ];

  const mainMenu = [
    {
      id: 1,
      name: "Regresar",
      toRoute: "/",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMoreMenu = (event) => {
    setAnchorMoreMenu(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  // useEffect(() => {
  //   if (currentTimer == null) {
  //     var timer = setInterval(() => {
  //       setCurrentDate(Date());
  //     }, 1000);
  //     setCurrentTimer(timer);
  //   }
  // }, [currentTimer]);

  // useEffect(() => {
  //   return () => {
  //     clearInterval(currentTimer);
  //   };
  // }, []);

  const returnToMain = () => {
    //window.location.href = "https://www.sweeven.life";
    navigator(-1);
  };

  const createMenu = () => {
    return mainMenu.map((menu) => {
      return (
        <Grid key={menu.id} item>
          <Typography variant="text" onClick={returnToMain}>
            {menu.name}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={returnToMain}
                edge="start"
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            {createMenu()}
            <Grid item xs />
            <Grid item>
              <Button
                variant="text"
                href="https://sweeven.life"
                sx={{ color: "white" }}
              >
                {"Ir a sweeven.life"}
              </Button>
            </Grid>
            {/*  */}
            {/* <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="end"
              >
                <MenuIcon />
              </IconButton>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
