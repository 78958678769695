import React from "react";
import { Container, CssBaseline, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ConstructionIcon from "@mui/icons-material/Construction";
import { app } from "../firebase/firebaseConfig";
import { logEvent, getAnalytics } from "firebase/analytics";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  renderView = () => {
    return (
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            m: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack>
            <ConstructionIcon />
            <Typography>{"weAreWorking"}</Typography>
          </Stack>
        </Box>
      </Container>
    );
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(info);
    console.log(`ERROR: ${error}`);
    console.log(`INFO: ${JSON.stringify(error, null, 2)}`);
    const analytics = getAnalytics(app);
    logEvent(analytics, "select_content", {
      content_type: "image",
      content_id: "P12453",
      items: [{ name: "Kittens" }],
    });
    console.log(info);
  }

  render() {
    if (this.state.hasError) {
      return this.renderView();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
