import React, { useEffect, useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PhoneNumberComponent } from "../components/PhoneNumberComponent";
import BasePage from "../pages/BasePage";
import {
  Box,
  Grid,
  Alert,
  AlertTitle,
  Typography,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// import { useTranslation } from "react-i18next";
import logo from "../images/logo_dark.svg";
import logoLight from "../images/logo_light.svg";

export default function SimpleForm() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(Boolean());
  const [data, setData] = useState({
    name: String(),
    email: String(),
    phone: {
      code: String(),
      number: String(),
    },
  });

  const registerNewProspect = useCallback(async () => {
    try {
      const endpoint = `${process.env.REACT_APP_HOST}/prospects`;
      console.log("====================================");
      console.log(data);
      console.log("====================================");
      // let response = await axios.post(endpoint, data);
      // if (response.code === 1) {
      //   //TODO: HANDLE ERROR
      // }
      // navigate("/success");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [data, navigate]);

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handlePhoneChange = (name, phone) => {
    console.log("====================================");
    console.log(phone);
    console.log("====================================");
    setData({
      ...data,
      [name]: phone,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    registerNewProspect();
  };

  return (
    <BasePage disableHeader={true} disableTopWarning={true}>
      <Box sx={{ mb: 3, mr: 0, ml: 0 }}>
        <Box
          component="img"
          sx={{
            m: 3,
            width: { xs: "50vw", sm: "10vw" },
            height: "auto",
            mb: 6,
          }}
          src={theme.palette.mode === "dark" ? logo : logoLight}
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  mb: 2,
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                }}
              >
                {"🌟 ¡DESCUBRE TU DESCUENTO! 🌟"}
              </Typography>
              <img
                className="formImage"
                src={require(`../images/all.png`)}
                alt={"producImage"}
                // style={{ width: { xs: "50vw", sm: "20vw" }, height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 1, mr: { xs: 0, md: 15 } }}>
              <Box component="form" onSubmit={handleSubmit}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>{error.title}</AlertTitle>
                    {error.message}
                  </Alert>
                )}
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {
                    "Deja tus datos para obtener un código de descuento especial en Sweeven."
                  }
                </Typography>
                <TextField
                  onChange={handleTextChange}
                  value={data.name}
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label={"Nombre"}
                  name="name"
                  autoComplete="name"
                  type="name"
                  color={theme.palette.mode === "dark" ? "white" : "black"}
                  // inputProps={{
                  //   style: { color: "white", backgroundColor: "#cfcfcf2e" },
                  // }}
                  // sx={{
                  //   color: "white",
                  // }}
                />
                <TextField
                  onChange={handleTextChange}
                  value={data.email}
                  margin="normal"
                  variant="filled"
                  required
                  fullWidth
                  id="email"
                  label={"Correo Electrónico"}
                  name="email"
                  autoComplete="email"
                  color={theme.palette.mode === "dark" ? "white" : "black"}
                  type="email"
                  // inputProps={{
                  //   style: { color: "white", backgroundColor: "#cfcfcf2e" },
                  // }}
                  // sx={{
                  //   color: "white",
                  // }}
                />

                <PhoneNumberComponent
                  required
                  value={data.phone}
                  name="phone"
                  label={"Número"}
                  onChange={handlePhoneChange}
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={loading}
                  sx={{ mt: 3, bgColor: "primary" }}
                >
                  {"OBTENER DESCUENTO"}
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            textAlign: "center",
            fontFamily: "Montserrat",
            fontWeight: "900",
          }}
        >
          {"🐝 ¡Gracias por ser parte de Sweeven! 🐝"}
        </Typography>
      </Box>
    </BasePage>
  );
}
