import React from "react";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar, Toolbar, Typography, CssBaseline } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import Navigator from "../components/Navigator";
import theme from "../ColorsConfig";
import bgImageLight from "../resources/images/bg1.png";
import bgImage from "../resources/images/bg-2.png";

export default function BasePage(props) {
  const [currentMenu, setCurrentMenu] = React.useState(0);
  const theme = useTheme();
  const onChange = (value) => {
    setCurrentMenu(value);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 300;

  const renderHeader = () => {
    if (!props.disableHeader) {
      return <Header onDrawerToggle={handleDrawerToggle} title="Inicio" />;
    }
  };

  const renderTopWarning = () => {
    if (!props.disableTopWarning) {
      return (
        <Typography
          sx={{ fontSize: 12, m: 2, color: "white", fontWeight: "bold" }}
        >
          {
            "ESTE PRODUCTO NO ES UN MEDICAMENTO. EL CONSUMO DE ESTE PRODUCTO ES RESPONSABILIDAD DE QUIEN LO RECOMIENDA Y DE QUIEN LO USA"
          }
        </Typography>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          textAlign: "center",
          backgroundImage: `url(${
            theme.palette.mode === "dark" ? bgImage : bgImageLight
          })`,
          backgroundColor: theme.palette.mode === "dark" ? "" : "#F9FAFB",
        }}
      >
        <CssBaseline />
        {/* <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {" "}
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          />
        </Box> */}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",

            //alignItems: "center",
            //justifyContent: "center",
          }}
        >
          {renderHeader()}
          {renderTopWarning()}
          <Box component="main" sx={{ flex: 1, px: 4 }}>
            {props.children}
          </Box>
        </Box>
        {/* <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {"AQII"}
            </Typography>
          </Toolbar>
        </AppBar> */}
      </Box>
      {/* <Typography sx={{ fontSize: 10, m: 2, color: "white" }}>
        {
          "ESTE PRODUCTO NO ES UN MEDICAMENTO. EL CONSUMO DE ESTE PRODUCTO ES RESPONSABILIDAD DE QUIEN LO RECOMIENDA Y DE QUIEN LO USA"
        }
      </Typography>
      <Box>{props.children}</Box> */}
    </ThemeProvider>
  );
}
