import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import BasePage from "../pages/BasePage";
import { Box, Stack, Typography, Grid } from "@mui/material";
import dosis from "../dosis.json";
import { useNavigate } from "react-router-dom";
import { getColorByIndex } from "../extensions/Tools";
import { normalizeStrings } from "../extensions/StringsExtensions";

export default function SicksListPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [textToFilter, setTextToFilter] = useState("");

  const itemBackgroundColor = () => {
    return theme.palette.mode !== "dark"
      ? "linear-gradient(90deg, #DFE7F4 0%, #F5F9FD 100%)"
      : "linear-gradient(90deg, #252629 0%, #000000 100%)";
  };

  const renderSicks = () => {
    var result = [];
    result = textToFilter === "" ? dosis : filterSicks(textToFilter);
    return result.map((option, index) => {
      return (
        <Box
          key={option.sick}
          sx={{
            borderRadius: 10,
            background: itemBackgroundColor(),
            borderWidth: 3,
            borderColor: getColorByIndex(index % 4),
            borderStyle: "solid",
          }}
          onClick={() => {
            handleOptionSelected(option);
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {option.sick}
          </Typography>
        </Box>
      );
    });
  };

  const filterSicks = (text) => {
    normalizeStrings(text, text);
    return dosis.filter((sick) =>
      normalizeStrings(sick.sick.toLowerCase()).includes(
        normalizeStrings(text.toLowerCase())
      )
    );
  };

  const handleOptionSelected = (option) => {
    navigate("/calculator/detail", { state: { data: option } });
  };

  const handleTextChange = (event) => {
    console.log(event.target.value);
    setTextToFilter(event.target.value);
  };

  return (
    <BasePage>
      <Stack
        sx={{ maxWidth: "md", margin: "auto", overflow: "auto" }}
        spacing={3}
      >
        <Grid container>
          <Grid item>
            <Box
              sx={{
                backgroundColor: "#373737",
                textAlign: "left",
                borderRadius: 3,
                p: 2,
                mb: 2,
                color: "#FFF",
              }}
            >
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {"INSTRUCCIONES:"}
              </Typography>
              <Typography variant="body" sx={{}}>
                {
                  " BUSCA DENTRO DEL LISTADO QUE A CONTINUACIÓN TE PRESENTAREMOS, EL PADECIMIENTO, ENFERMEDAD O ALTERACION DE LA SALUD Y DALE CLIC."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <input
          type="search"
          placeholder="Buscar padecimiento"
          onChange={handleTextChange}
          style={{
            margin: 5,
            padding: 5,
            backgroundColor: "black",
            color: "white",
            borderRadius: 10,
            fontSize: 16,
          }}
        ></input>
        {renderSicks()}
      </Stack>
    </BasePage>
  );
}
