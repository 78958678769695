import * as React from "react";
import "./App.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  responsiveFontSizes,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
// import theme from "./ColorsConfig";
import { AppRouter } from "./routers/AppRouter";
import ErrorBoundary from "./helpers/ErrorBoundary";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// export default function ToggleColorMode() {
//   const [mode, setMode] = React.useState("light");
//   const colorMode = React.useMemo(
//     () => ({
//       toggleColorMode: () => {
//         setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
//       },
//     }),
//     []
//   );

//   const theme = React.useMemo(
//     () =>
//       createTheme({
//         palette: {
//           mode,
//         },
//       }),
//     [mode]
//   );

//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ErrorBoundary>
//         <ThemeProvider theme={theme}>
//           <AppRouter />
//         </ThemeProvider>
//       </ErrorBoundary>
//     </ColorModeContext.Provider>
//   );
// }

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            light: "#FFF",
            main: "#000",
            dark: "#000",
          },
          secondary: {
            main: "#081b57",
          },
          harmony: {
            main: "#5DCA83",
            light: "#5DCA83",
            dark: "#5DCA83",
          },
          white: {
            main: "#FFF",
          },
          black: {
            main: "#000",
          },
        },
      }),
    [prefersDarkMode]
  );
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AppRouter />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
