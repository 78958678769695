import React, { useEffect, useState } from "react";
import BasePage from "../pages/BasePage";
import { Grid, Typography, Box, Fab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ProductsList from "../products.json";
import { Stack } from "@mui/system";
import { resolveIcon } from "../services/IconsService";

export default function ProductDetailPage(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [productSelected, setproductSelected] = useState({
    name: String(),
    image: String(),
    sku: String(),
    link: String(),
    description: String(),
  });

  useEffect(() => {
    const sku = location.state ? location.state.data : null;
    setproductSelected(ProductsList.products.find((x) => x.sku === sku));
  }, [setproductSelected, location.state]);

  const renderIcons = (icons) => {
    return (
      <Stack direction={{ xs: "row", sm: "column" }}>
        {icons.map((icon, index) => {
          let ProductIcon = resolveIcon(icon);
          return <ProductIcon sx={{ height: 128, width: 128 }} key={index} />;
        })}
      </Stack>
    );
  };

  const renderView = () => {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        {/* TITLE */}

        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h3"
          >
            {productSelected.name}
          </Typography>
        </Grid>

        {/* IMAGE */}

        <Grid
          item
          xs={12}
          sm={productSelected.icons ? 6 : 12}
          sx={{ position: "relative" }}
        >
          {productSelected.image && (
            <img
              src={require(`../images/${productSelected.image}`)}
              alt={"producImage"}
              style={{ height: "40vh" }}
            />
          )}
        </Grid>

        {/* ICONS */}
        {productSelected.icons && (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",

              textAlign: "justify",
            }}
          >
            {renderIcons(productSelected.icons)}
          </Grid>
        )}

        {/* DESCRIPTION */}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",

            textAlign: "justify",
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: productSelected.description }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage>
      <Box
        sx={{ maxWidth: "md", margin: "auto", overflow: "hidden", pb: "6vh" }}
      >
        <Box align="right">
          <CancelIcon
            sx={{ color: "white", fontSize: 30 }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>
        {renderView()}
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          href={productSelected.promotionalLink ?? productSelected.link}
          sx={{
            background: "#5DCA83",
            fontSize: "25wh",
            fontWeight: "bold",
            position: "fixed",
            m: 3,
            bottom: 10,
            right: 15,
          }}
        >
          {productSelected.promotionalLink ? (
            <MoneyOffIcon sx={{ mr: 1 }} />
          ) : (
            <ShoppingCartIcon sx={{ mr: 1 }} />
          )}
          {productSelected.promotionalLink
            ? "PAGA SOLO EL ENVIO"
            : "ADQUIERE EL TUYO"}
        </Fab>
      </Box>
    </BasePage>
  );
}
