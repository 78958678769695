import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CalculatorPage from "../pages/CalculatorPage";
import SicksListPage from "../pages/SicksListPage";
import OilDetailPage from "../pages/OilDetailPage";
import SurveyPage from "../pages/SurveyPage";
import HomePage from "../pages/HomePage";
import SuccessPage from "../pages/SuccessPage";
import SimpleForm from "../pages/SimpleForm";
import ProductDetailPage from "../pages/ProductDetailPage";
import ScrollToTop from "../helpers/ScrollToTop";

export const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* <PublicRoute path="login" /> */}
        {/* <PrivateRouter path="/menu" element={<MenuOrderPage />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/sicks" element={<SicksListPage />} />
        <Route path="/calculator/list" element={<SicksListPage />} />
        <Route path="/calculator/detail" element={<OilDetailPage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/form" element={<SimpleForm />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/product-detail" element={<ProductDetailPage />} />
      </Routes>
    </Router>
  );
};
